import React from 'react';
import { Link } from 'gatsby';
import PostDetails from '../PostDetails/PostDetails';
import styles from './PostCard.module.css';
import Img from 'gatsby-image';

export interface PostCardProps {
  slug: string;
  title: string;
  date: string;
  description: string;
}

const PostCard = ({ slug, title, date, description, readingTime, image }) => {
  let headerImage;
  if (image) {
    headerImage = <Img className={styles.headerImage} fluid={image} />;
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        <Link to={slug}>{title}</Link>
      </h2>
      <PostDetails date={date} readingTime={readingTime} />
      <Link to={slug} title={title}>
        {headerImage}
      </Link>
      <p
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      <div className={styles.readMore}>
        <Link to={slug}>Read more...</Link>
      </div>
    </div>
  );
};

export default PostCard;

import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import SiteMetadata from '../components/SiteMetadata/SiteMetadata';
// import TagList from '../components/TagList/TagList';
import styles from './index.module.css';
import PostCard from '../components/PostCard/PostCard';

const Index = ({ data, location }) => {
  const { title, keywords } = data.site.siteMetadata;
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout title={title} location={location}>
      <SiteMetadata title="All posts" keywords={keywords} />
      <div className={styles.container}>
        {/* <strong>{data.allMarkdownRemark.totalCount} Posts</strong> */}
        {/* <TagList /> */}

        <div>
          {posts.map(({ node }) => {
            const slug = node.fields.slug;
            const title = node.frontmatter.title || slug;
            const date = node.frontmatter.date;
            const description = node.frontmatter.description || node.excerpt;
            const readingTime = node.fields.readingTime.text;

            let image;
            if (node.frontmatter.image) {
              image = node.frontmatter.image.childImageSharp.fluid;
            }

            return (
              <PostCard
                key={slug}
                title={title}
                slug={slug}
                date={date}
                description={description}
                readingTime={readingTime}
                image={image}
              />
            );
          })}
        </div>

        {/* <div className={styles.contacts}>
          <div>Follow me on:</div>
          <div>
            <strong>
              <a href="https://twitter.com/DenysVuika">Twitter</a>
            </strong>
          </div>
          <div>
            <strong>
              <a href="https://github.com/DenysVuika">GitHub</a>
            </strong>
          </div>
        </div> */}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        keywords
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { draft: { eq: false } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            description
            image {
              childImageSharp {
                fluid(maxWidth: 768) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
            readingTime {
              text
            }
          }
          excerpt
        }
      }
    }
  }
`;

export default Index;
